















import Vue from "vue";
import Component from "vue-class-component";
import { namespace } from "vuex-class";
import LanguageTabs from "../LanguageTabs.vue";

const legalNamespace = namespace("Legal");

@Component({ components: {} })
export default class CookiePrivacy extends Vue {
  isLoading = false;

  @legalNamespace.Getter("generalConditions")
  generalConditions!: { [lang: string]: string };

  @legalNamespace.Getter("cookie")
  cookiePolicy!: { [lang: string]: string };

  @legalNamespace.Getter("privacy")
  privacyPolicy!: { [lang: string]: string };

  @legalNamespace.Action("fetchAll")
  fetchAll!: () => Promise<any>;

  get cookiePolicyTranslated() {
    return this.cookiePolicy[this.$i18n.locale];
  }

  get privacyPolicyTranslated() {
    return this.privacyPolicy[this.$i18n.locale];
  }

  get generalConditionsTranslated() {
    return this.generalConditions[this.$i18n.locale];
  }

  async doFetch() {
    this.isLoading = true;
    await this.fetchAll();
    this.isLoading = false;
  }

  created() {
    this.doFetch();
  }
}
